
/* Login Styling
---------------------------------------- */

body.login {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body.login h1 a {
    background-size: contain !important;
    background-position: center center !important;
    height: 90px !important;
    width: 100% !important;
}

body.login #login_error,
body.login .message {
    border-left: 4px solid #3F080F;
}

.login.wp-core-ui .button-primary {
    background: #3F080F;
    border-color: #3F080F;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    text-decoration: none;
    text-shadow: none;
    text-transform: uppercase;
}

.login.wp-core-ui .button-primary:hover {
    background: #000;
    border-color: #000;
}